@mixin grid-at-bp($bp) { 
	.grid {
		&__item {
			&--hide-bp-#{$bp} {
				display: none;
			}
			&--col-1-#{$bp} {
				min-width: 8.33%;
				width: 8.33%;
			}
			&--col-2-#{$bp} {
				min-width: 16.66%;
				width: 16.66%;
			}
			&--col-3-#{$bp} {
				min-width: 25%;
				width: 25%;
			}
			&--col-4-#{$bp} {
				min-width: 33.33%;
				width: 33.33%;
			}
			&--col-5-#{$bp} {
				min-width: 41.66%;
				width: 41.66%;
			}
			&--col-6-#{$bp} {
				min-width: 50%;
				width: 50%;
			}
			&--col-7-#{$bp} {
				min-width: 58.33%;
				width: 58.33%;
			}
			&--col-8-#{$bp} {
				min-width: 66.66%;
				width: 66.66%;
			}
			&--col-9-#{$bp} {
				min-width: 75%;
				width: 75%;
			}
			&--col-10-#{$bp} {
				min-width: 83.33%;
				width: 83.33%;
			}
			&--col-11-#{$bp} {
				min-width: 91.66%;
				width: 91.66%;
			}
			&--col-12-#{$bp} {
				min-width: 100%;
				width: 100%;
			}
		}
	}
}

.grid {
	display: flex;
	flex-direction: row;
	width: 100%;
	flex-wrap: wrap;
	align-items: flex-start;
	max-width: 1440px;

	&__row {
		display: flex;
		margin-bottom: 48px;
		flex-wrap: wrap;
		min-width: 100%;
		width: 100%;
	}


	&__item {
		display: flex;
		flex-direction: column;

		&--col-1 {
			min-width: 8.33%;
			width: 8.33%;
		}
		&--col-2 {
			min-width: 16.66%;
			width: 16.66%;
		}
		&--col-3 {
			min-width: 25%;
			width: 25%;
		}
		&--col-4 {
			min-width: 33.33%;
			width: 33.33%;
		}
		&--col-5 {
			min-width: 41.66%;
			width: 41.66%;
		}
		&--col-6 {
			min-width: 50%;
			width: 50%;
		}
		&--col-7 {
			min-width: 58.33%;
			width: 58.33%;
		}
		&--col-8 {
			min-width: 66.66%;
			width: 66.66%;
		}
		&--col-9 {
			min-width: 75%;
			width: 75%;
		}
		&--col-10 {
			min-width: 83.33%;
			width: 83.33%;
		}
		&--col-11 {
			min-width: 91.66%;
			width: 91.66%;
		}
		&--col-12 {
			min-width: 100%;
			width: 100%;
		}
	}
}