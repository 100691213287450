$baseclass: 'card';

.#{$baseclass} {
  position: relative;
  width: 100%;
  margin-bottom: 3 * $rem-base;

  &__header, &__bar, &__body {
    border-radius: 8px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  &__header, &__body {
    padding: (3 * $rem-base) (4 * $rem-base);
    border: solid 3px;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: (8 * $rem-base);
  }

  &__body {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
    border-radius: 0;
    display: flex;
    flex-direction: wrap;
    max-height: 0;
    transition: max-height $duration-short $easing1;
    overflow: scroll;
  }

  &__body__left {
    display: flex;
    flex-direction: column;
    border-right: solid 3px;
  }

  &__header--left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  @keyframes zoominoutsinglefeatured {
      0% {
          transform: scale(1,1);
      }
      50% {
          transform: scale(1.03,1.03);
      }
      100% {
          transform: scale(1,1);
      }
  }

  @keyframes wait {
    from { transform: translateY(-1500000px); }
    to { transform: translateY(-150000px); }
  }

  @keyframes slideInFromLeft {
    from { transform: translateX(-100px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  @keyframes wave {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-7px);
    }
  }

  @keyframes appear {
    to {
      visibility: visible;
    }
  }

  &__loading {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    align-items: center;
    padding-top: "100px";
    visibility: hidden;
    animation: appear 0s forwards 1.5s;
  }

  &__delay {
    visibility: hidden; /* initial state */
    animation: appear 0s forwards 1.5s;
  }

  &__letter {
    animation: wave 1s ease-in-out infinite 1.5s;
  }

  &__cardholder_slide {
    animation: 0.5s ease-out 0s 1 wait, 0.21s ease-out 0.5s 1 slideInFromLeft;
    margin-left: auto;
    margin-right: auto;
  }
  &__body__token-logo {
    display: grid;
    grid-template-columns: 70px 1fr;
  }

  &__body__column__one {
    grid-column: 1 ;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    //text-align: center;
    width: "auto";
  }

  &__body__column__two {
    grid-column: 2/4 ;
    grid-row: 1;
    margin-left: auto;
    margin-right: auto;
    padding-right: 8px;
    padding-left: 8px;
  }

  &__body__column__eight {
    grid-column: 1/4;
    grid-row: 2/5;
    margin-bottom: 2 * $rem-base;
    margin-top: 2 * $rem-base;
    padding-right: 8px;
  }

  &__body__column__nine {
    grid-column: 4/6;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 3 * $rem-base;
    margin-left: 32px;
    margin-bottom: 2 * $rem-base;
  }

  &__token__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1 * $rem-base;

    transition: transform $easing1 $duration-short;
    cursor: pointer;
  }
  &__refresh__button {
    transition: transform $easing1 $duration-short;
    cursor: pointer;
  }


  &__dep-buttons-col {
    margin-right: auto;
    width: 150px;
  }

  &__dep-buttons-flex {
    display: none;
  }
  &__about-box {
    padding: (1 * $rem-base) (1 * $rem-base);
    border: solid 3px;
    //border-radius: 8px;
    max-height: 250px;
    overflow: scroll;
    padding-bottom: 0.25 * $rem-base;
    margin-bottom: 1 * $rem-base;
  }

  &__body__column {
    margin-right: 4 * $rem-base;
    margin-bottom: 4 * $rem-base;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &:first-child {
      border-right: solid 2px rgba(white, 0.33);
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 2 * $rem-base;
      }
    }
  }

  &__header__balances {
    display: flex;
    flex: 1;
    > * {
      &:not(:first-child) {
        margin-left: px-to-rem($rem-base * 3);
      }
    }
  }

  &__header--right {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    > * {
      margin-left: px-to-rem($rem-base * 3);
    }
  }

  &__bar {
    height: $rem-base;
    width: 100%;
    background: $brand-white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__pool-pic {
    height: auto;
    width: auto;
    max-width: 200px;
  }
  &__balance-grid--inner {
    display: grid;
    grid-template-columns: 70px 1fr;
  }

  &__balance-grid--outer {
    display: flex;
  }

  &__balance-box {
    display: grid;
    width: 250px;
    flex: 0 0 250;
  }

  &__open-button {
    transition: transform $easing1 $duration-short;
    cursor: pointer;
  }

  &--loaded {
    .#{$baseclass}__body__column__nine {
      animation: zoominoutsinglefeatured 1s;
    }
  }
  &--open {
    .#{$baseclass}__body {
      max-height: 1500px;
    }
    .#{$baseclass}__open-button {
      transform: rotate(-45deg);
    }

    .#{$baseclass}__body__column__eight {
      transform: scale(1.0);
    }

    .#{$baseclass}__balance-grid--outer {
      animation: zoominoutsinglefeatured 1s;
    }

    .#{$baseclass}__body__column__two {
      transform: scale(1.0);
    }

    .#{$baseclass}__token__buttons {
      transform: scale(1.0);
    }
  }

  @media screen and (max-width: $bp-medium) {

    .#{$baseclass}__body {
      flex-direction:column;
    }

    .#{$baseclass}__body__left {
      border-right: none;
    }

    .#{$baseclass}__body__column__nine {
      margin-left: 0px;
      margin-bottom: 0px;
      padding-top: 1 * $rem-base;
    }

    .#{$baseclass}__dep-buttons-col {
      display: none;
    }

    .#{$baseclass}__dep-buttons-flex {
      display: flex;
      flex-direction: column-reverse;
      margin-left: auto;
      width: 112px;
    }
  }

  @media screen and (max-width: $bp-small) {

    .#{$baseclass}__header {
      padding: (1 * $rem-base);
    }

    .#{$baseclass}__body {
      padding-right: (1 * $rem-base);
      padding-left: (1 * $rem-base);
    }

    .#{$baseclass}__about-box {
      max-height: 125px;
    }

    .#{$baseclass}__pool-pic {
      max-width: 125px;
    }

    .#{$baseclass}__balance-grid--inner {
      grid-template-columns: 56px 1fr;
    }

    .#{$baseclass}__balance-box {
      width: 150px;
      flex: 0 0 150;
    }

    .#{$baseclass}__body__column__two {
      padding-right: 3px;
      padding-left: 3px;
    }

    .#{$baseclass}__body__column__eight {
      margin-bottom: 0px;
    }
  }
}