@media screen and (min-width: $bp-xxlarge) {
	h1 {
		font-size: px-to-rem($rem-base * 13);
	}

	h6 {
		font-size: px-to-rem(48px);
	}

	@include grid-at-bp(xxlarge);
}

@media screen and (max-width: $bp-xxlarge) {
	h1 {
		font-size: px-to-rem($rem-base * 13);
	}

	h6 {
		font-size: px-to-rem(48px);
	}

	@include grid-at-bp(xxlarge);
}

@media screen and (max-width: $bp-large) {
	h1 {
		font-size: px-to-rem($rem-base * 13);
	}

	h6 {
		font-size: px-to-rem(48px);
	}

	.horizontal-padding {
		@include horizontalPadding($rem-base * 6);
	}

	@include grid-at-bp(large);
}

@media screen and (max-width: $bp-medium) {
	h1 {
		font-size: px-to-rem(48px);
		margin-bottom: 24px;
		letter-spacing: $letter-spacing-xs;
	}

	h6 {
		font-size: px-to-rem(36px);
	}
	.page-section {
		@include verticalPadding(($rem-base * 8));
	}

	.horizontal-padding {
		@include horizontalPadding(($rem-base * 5));
	}

	@include grid-at-bp(medium);
}

@media screen and (max-width: $bp-small) {
	h1 {
		font-size: px-to-rem(36px);
	}

	h6 {
		font-size: px-to-rem(24px);
	}

	p {
		font-size: px-to-rem(10px);
		margin-bottom: 8px;
	}
	.horizontal-padding {
		@include horizontalPadding(($rem-base * 1.25));
	}
}

@media screen and (max-width: $bp-xsmall) {
	h1 {
		font-size: px-to-rem(24px);
	}
	h6 {
		font-size: px-to-rem(24px);
	}
}
