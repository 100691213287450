@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shapes {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  animation: fadeIn $easing2 $duration-longest forwards;
}
