$baseclass: 'app-bar';

.#{$baseclass} {
  position: fixed;
  top: 0;
  width: 100%;

  display: flex;
  align-items: center;

  height: 100px;

  background: linear-gradient(180deg, $brand-white 75%, transparent);



  .logo {
    margin-right: 0.1 * $rem-base;
    margin-top: -3px;
  }

  &__left {
    display: flex;
    align-items: flex-start;
  }

  .#{$baseclass}__items__left {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      background: transparent;
    }

    > * {
      .button {
        margin-left: 0;
      }
    }
  }

  .#{$baseclass}__tvl {
    flex: 1;
    display: flex;
    margin-bottom: 3px;
  }

  .#{$baseclass}__logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .#{$baseclass}__connect {
    display: flex;
    gap: 3px;
  }

  .#{$baseclass}__items__right {
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      background: transparent;
    }

    > * {
      .button {
        margin-left: 0;
      }
    }
  }

  a {
    &:nth-child(1).active .button__bar--inner {
      background: $brand-grey-dark;
    }

    &:nth-child(2).active .button__bar--inner {
      background: $brand-grey-dark;
    }

    &:nth-child(3).active .button__bar--inner {
      background: $brand-grey-dark;
    }

    &:nth-child(4).active .button__bar--inner {
      background: $brand-grey-dark;
    }
  }

  @media screen and (max-width: $bp-medium) {
    .#{$baseclass}__items__left > a {
      display: none;
    }
  }

  @media screen and (max-width: $bp-small) {
    .#{$baseclass}__items__right {
      display: none;
    }
    height: 130px;

    .#{$baseclass}__logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
  }

  @media screen and (min-width: $bp-small) {
    .#{$baseclass}__connect {
      display: none;
    }
  }

}